import './carpreview.scss';

import React, { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import CONFIG from '../../../common/config';
import ROUTES from '../../../common/constants/routes.constants';
import { KiaContext } from '../../../common/context/main.context';
import GTM from '../../../common/services/gtm.service';
import { formatPrice } from '../../../common/utils/price.utils';
import Button from '../../01_atoms/button/Button';
import Icon from '../../01_atoms/icons/Icon';
import Engine from '../../02_molecules/engine/Engine';
import PriceBox from '../../02_molecules/priceBox/PriceBox';
import PriceInfo from '../../02_molecules/priceinfo/Priceinfo';
import PriceinfoLegal from '../../02_molecules/priceinfoLegal/PriceinfoLegal';
import PriceinfoPrice from '../../02_molecules/priceinfoPrice/PriceinfoPrice';
import badge from '../../05_buckets/car/graphics/coty_2022.svg';
import VideoOverlay from '../overlay/VideoOverlay';

export default function CarPreview(props) {
  const {
    car,
    onShowMore,
    showControls,
    className = '',
    scrollToConditions,
    movePrice
  } = props;

  const history = useHistory();
  const { cars, prefix, mobile, showMonthlyPrices, currentLocale, isB2c, bms } = useContext(KiaContext);
  const [ showVideo, setShowVideo ] = useState(false);
  const [ hidePrice, setHidePrice ] = useState(movePrice);

  useEffect(() => {
    setHidePrice(movePrice);
  }, [movePrice]);

  const classes = ['carpreview', ...className.split(' ')];

  const onPrevCar = () => {
    let prevCar = null;
    cars.forEach((_car, i) => {
      if (_car.id === car.id) {
        if (i > 0) {
          prevCar = cars[i-1];
        } else {
          prevCar = cars[cars.length - 1];
        }
      }
    });

    GTM.track({
      dlCategory: 'Car arrow',
      dlAction: 'Click',
      dlLabel: prevCar.slug,
      dlModel: car.slug,
    });

    history.push(`${prefix}${ROUTES.cars}/${prevCar.slug}`);
  };
  const onNextCar = () => {
    let nextCar = null;
    cars.forEach((_car, i) => {
      if (_car.id === car.id) {
        if (i < cars.length - 1) {
          nextCar = cars[i+1];
        } else {
          nextCar = cars[0];
        }
      }
    });

    GTM.track({
      dlCategory: 'Car arrow',
      dlAction: 'Click',
      dlLabel: nextCar.slug,
      dlModel: car.slug,
    });

    history.push(`${prefix}${ROUTES.cars}/${nextCar.slug}`);
  };

  return (
    <>
      <div className={classes.join(' ')}>
        <div className="carpreview__visual__overlay">
          <picture className="carpreview__visual">
            <img src={car.image} alt={car.name}></img>
          </picture>
        </div>

        <div className="carpreview__content">
          <div className="carpreview__engines">
          {
            car.engine_types.map(type => (
              <Engine type={type} key={`engine-${type}-${car.id}`} />
            ))
          }
          </div>
          <p className="carpreview__carname">
            { car.price_default_short_name }
            { car.badge ? (
              <span className="carpreview__badge">
                <img src={badge} alt="Car of the Year 2022" />
              </span>
            ) : null }
          </p>
          <CarPreviewPrice {...{mobile, hidePrice, showMonthlyPrices, car, currentLocale, isB2c}} />
        </div>
        { cars.length > 1 && showControls ? (
          <div className="carpreview__arrows">
            <Button color="transparent" className="fc--text-light carpreview__arrows__background" icon={<Icon icon="arrow-left-long" />} onClick={onPrevCar} />
            <Button color="transparent" className="fc--text-light carpreview__arrows__background" icon={<Icon icon="arrow-right-long" />} onClick={onNextCar} />
          </div>
        ) : null }
        { !mobile ? <div className="car__short__description mt--8 fc--text-light fs--normal bold" dangerouslySetInnerHTML={{__html: car.intro}} ></div> : null }
        { showControls ? (
          <div className="carpreview__actions__wrapper mt--2">
            <div className="carpreview__actions">
              <Button onClick={scrollToConditions} mode="outline" size={mobile ? 'small' : 'large'} className="carpreview__actions__conditionsbutton button--bold">{ bms ? <FormattedMessage id="preview.conditionsbms" /> : <FormattedMessage id="preview.conditions" /> }</Button>
              { (car || {}).gforce_hash ? (
                <Button onClick={onShowMore} size={mobile ? 'small' : 'large'} className="ml--2 carpreview__showcar button--bold" icon={<Icon icon="car360"/>} mode="seethrough"><FormattedMessage id="preview.showthiscar" className="carpreview__showcar"/></Button>
              ) : null }
              {/* { car.product_video ? (<Button onClick={openVideo} size={mobile ? 'small' : 'large'} color="light" mode="outline" className="button--bold ml--2"><FormattedMessage id="preview.video" /></Button>) : null } */}
            </div>
            <p className="mt--3 mb--3 p--3 fc--text-light bold">
            { showMonthlyPrices && isB2c ? <><FormattedMessage id={'detail.price.disclaimer.b2c.photo'} /> <FormattedMessage id={'detail.price.disclaimer.b2c.loan'} /></> : <FormattedMessage id={'detail.price.disclaimer.b2b'} />}
            </p>
          </div>
          ) : null }

        { showVideo && car.product_video ? (
          <VideoOverlay url={car.product_video.link} onClose={() => setShowVideo(false)} />
        ) : null }
      </div>
    </>
  );
}

const CarPreviewPrice = ({mobile, hidePrice, showMonthlyPrices, car, currentLocale, isB2c}) => {
  if (mobile || hidePrice) return null

  if (isB2c)
    return <PriceLine {...{car, currentLocale, showMonthlyPrices, isB2c, type: 'easymove'}} />


  const frEngineIsElectrical = car?.['financial_renting']?.is_electrical || false
  const frLegalMessageId = `priceinfo.legal.financial_renting.${frEngineIsElectrical ? 'electric' : 'fuel'}`
  const klEngineIsElectrical = car?.['kia_lease']?.is_electrical || false
  const klLegalMessageId = `priceinfo.legal.kia_lease.${klEngineIsElectrical ? 'electric' : 'fuel'}`

  // b2b
  return <div className='carpreview__content__prices'>
    <PriceBox {...{car, currentLocale, showMonthlyPrices, type: 'financial_renting', infoButtonData: {...{car, currentLocale, legalMessageId: frLegalMessageId, isB2c, type: 'financial_renting', show_tco: false}}}} />
    <PriceBox {...{car, currentLocale, showMonthlyPrices, type: 'kia_lease', infoButtonData: {...{car, currentLocale, legalMessageId: klLegalMessageId, isB2c, type: 'kia_lease', show_tco: false}}}} />
  </div>
}

const PriceLine = ({car, currentLocale, showMonthlyPrices, isB2c, type}) => {
  const intl = useIntl()
  const typeMonth = intl.formatMessage({id: `${type}.month`});

  const price = showMonthlyPrices ?
    formatPrice(car?.[type]?.price, CONFIG.PRICE_FORMAT, currentLocale.locale) :
    formatPrice(car.price_motor_show, CONFIG.PRICE_FORMAT, currentLocale.locale)
  const hasPrice = Number(price.replace(/\D+/g, '')) !== 0
  if (!hasPrice) return null

  return <div className="carpreview__price">
    <div className={`carpreview__price__${type}`}>
      <div className="mt--0 mb--0 carpreview__price__name"><FormattedMessage id={`${type}.label`} />&nbsp;</div>
      <div className="mt--0 mb--0 carpreview__price__label">
        { showMonthlyPrices ? (
          <p className="lowercase my--0 carpreview__price__amount carpreview__price__amount--monthly">
            <FormattedMessage id={`from`} />&nbsp;
            {price}&nbsp;/&nbsp;<span className="mr--2" dangerouslySetInnerHTML={{__html: typeMonth}} />*
          </p>
        ) : (
          <p className="my--0 bold carpreview__price__amount">{price}</p>
        ) }
      </div>
    </div>
    {type === 'easymove' && (
      <>
        <div>
          <FormattedMessage id={`easymove.amount_last_payment`} values={{ amount_last_payment: formatPrice(car.amount_last_payment, CONFIG.PRICE_FORMAT_LEGAL, currentLocale.locale)}} />
        </div>
        <div className="carpreview__price__info">
          <FormattedMessage id={`easymove.more_info`} />
          <InfoButton {...{car, currentLocale, legalMessageId: `priceinfo.legal.${type}`, isB2c, type}} button buttonLabel={<FormattedMessage id={`easymove.more_info_link`} />} />
        .</div>
      </>
    )}
  </div>
}

export const InfoButton = ({car, currentLocale, legalMessageId, isB2c, type, ...restProps}) => {

  return <div className="carpreview__price__infobutton">
    <PriceInfo
      title={car.title}
      subtitle={car.power}
      promo={car.price_default_promo_information}
      price={{
        catalog: car.price,
        discount: car.price_discount,
        bonus: car.price_bonus,
        netto: car.price_net,
        takeover: car.price_trade_bonus,
        salon: car.price_motor_show,
      }}
      actions={(
        <>
          { car.price_default_calculator_link ? (<Button href={car.price_default_calculator_link} mode="outline" size="small" color="dark" className="mt--2" iconAfter icon={<Icon icon="link" />}><FormattedMessage id="car.fiscalculator" /></Button>) : null }
        </>
      )}
      model={car.slug}
      {...restProps}
      isB2c={isB2c}
    >
      <PriceinfoPrice {...{isB2c, car, locale: currentLocale.locale, type}} />
      <PriceinfoLegal {...{car, type, locale: currentLocale.locale, messageId: legalMessageId}} />
    </PriceInfo>
  </div>
}
