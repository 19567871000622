import './car.scss';

import React, { useContext, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';

import Button from '../../01_atoms/button/Button';
import BackButton from '../../02_molecules/backButton/BackButton';
import Banner from '../../02_molecules/banner/Banner';
import Box from '../../02_molecules/box/Box';
import ButtonBanner from '../../02_molecules/buttonBanner/ButtonBanner';
// import AllCarsswitch from '../../03_organisms/allcarsswitch/AllCarsswitch';
import CONFIG from '../../../common/config';
import CONFIGURATOR from '../../../common/constants/configurator.constants';
import { KiaContext } from '../../../common/context/main.context';
import GTM from '../../../common/services/gtm.service';
import Icon from '../../01_atoms/icons/Icon';
import CarDetails from '../../02_molecules/cardetails/CarDetails';
import CarouselSlick from '../../02_molecules/carousel/CarouselSlick';
import CarPreview from '../../03_organisms/carpreview/CarPreview';
// import Info from '../../02_molecules/info/Info';
import Loading from '../../02_molecules/loading/Loading';
import Powertrain from '../../02_molecules/powertrain/Powertrain';
import Page from '../../04_layouts/page/Page';
// import Carswitch from '../../03_organisms/carswitch/Carswitch';
import ROUTES from '../../../common/constants/routes.constants';
import STORAGE from '../../../common/constants/storage.constants';
import { sendToConfigurator } from '../../../common/services/configurator.service';
import { setItemTemp } from '../../../common/services/storage.service';
import img_warranty_fr from './graphics/7yw_fr.svg';
import img_warranty_nl from './graphics/7yw_nl.svg';

export default function Car() {
  const params = useParams();
  const history = useHistory();
  const intl = useIntl();
  const { cars, legal, prefix, mobile, currentLocale, showMonthlyPrices, isB2c, bms } = useContext(KiaContext);

  const configurator = useRef();
  const content = useRef();
  const powertrains = useRef();
  const colorsWrapper = useRef();
  const conditions = useRef();
  const banner = useRef();

  const [ loading, setLoading ] = useState(true);
  const [ car, setCar ] = useState(null);
  const [ interactive, setInteractive ] = useState(false);
  const [ materials, setMaterials ] = useState([]);
  const [ animations, setAnimations ] = useState([]);
  const [ showInterior, setShowInterior ] = useState(false);
  const [ lightsOn, setLightsOn ] = useState(false);
  const [ frontDoorsOpen, setFrontDoorsOpen ] = useState(false);
  const [ backDoorsOpen, setBackDoorsOpen ] = useState(false);
  const [ trunkOpen, setTrunkOpen ] = useState(false);
  const [ activeColor, setActiveColor ] = useState(null);
  const [ showBackseats, setShowBackseats ] = useState(CONFIG.DEFAULT_CAR_VIEW_INTERIOR === CONFIGURATOR.animations.backseats);
  const [ showFrontseats, setShowFrontseats ] = useState(CONFIG.DEFAULT_CAR_VIEW_INTERIOR === CONFIGURATOR.animations.frontseats);
  const [ showTop, setShowTop ] = useState(false);
  const [ showBanner, setShowBanner ] = useState(showMonthlyPrices);
  const [ keepClosed, setKeepClosed ] = useState(false);
  const [ activeSlide, setActiveSlide ] = useState(0);
  // const [ showHelp, setShowHelp ] = useState(false);

  const [ canColorsLeft, setCanColorsLeft ] = useState(false);
  const [ canColorsRight, setCanColorsRight ] = useState(true);
  const [ movePrice, setMovePrice ] = useState(false);

  const newCars = [ 'Sportage', 'EV6']; // 'Ceed', 'Ceed SW', 'ProCeed', 'EV9'

  const observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        if (entry.target.isSameNode(configurator.current)) {
          setShowTop(entry.intersectionRatio < .6 && entry.boundingClientRect.y <= 0);
        }

        if (entry.target.isSameNode(powertrains.current)) {
          setShowBanner(entry.intersectionRatio > .1);
        }

        if (entry.target.isSameNode(banner.current)) {
          const box = banner.current.getBoundingClientRect();
          setMovePrice(box.y === -1);
        }
      }
    })
  }, {
    rootMargin: '0px',
    threshold: [1, .8, .6, .4, .2, .1, 0.07, 0],
  });

  const init = () => {
    if (!cars.length) { history.push(prefix); return; }

    const _car = cars.find(({ slug }) => slug === params.slug);

    if (!_car || !_car.id) {
      history.push(ROUTES.notfound);
      return;
    }

    setCar(_car);

    setShowBanner(showMonthlyPrices);
    if (showMonthlyPrices) {
      setKeepClosed(false);
    }

    if (window.location.hash.includes('conditions')) {
      setTimeout(() => {
        if (CONFIG.DEBUG) { console.log('scroll to conditions'); }
        scrollToConditions(true);
      }, 500);
    }
  }

  const reset = () => {
    if (car) {
      setInteractive(false);
      setMaterials([]);
      setAnimations([]);
      setInterior(false);
      setLightsOn(false);
      setFrontDoorsOpen(false);
      setBackDoorsOpen(false);
      setTrunkOpen(false);
      setActiveColor(null);
    }
    setLoading(true);
  };

  const backToShowroom = () => {
    setItemTemp(STORAGE.showroomLocation, {
      pitch: car.hotspot[0].pitch,
      yaw: car.hotspot[0].yaw,
    });
    history.push(`${prefix}${ROUTES.showroom}`);
  };

  const interactiveBack = () => {
    setInteractive(false);
  }

  const toggleAnimation = (name) => {
    sendToConfigurator({
      action: CONFIGURATOR.toggleAnimation,
      data: name,
    });
  }

  const setColor = (name) => {
    GTM.track({
      dlCategory: 'Customize',
      dlAction: 'Color',
      dlLabel: name,
      dlModel: car.slug,
    });

    sendToConfigurator({
      action: CONFIGURATOR.setColor,
      data: name,
    });

    setActiveColor(name);
  }

  const setInterior = (value, fromAction) => {
    setShowInterior(value);

    setShowBackseats(CONFIG.DEFAULT_CAR_VIEW_INTERIOR === CONFIGURATOR.animations.backseats);
    setShowFrontseats(CONFIG.DEFAULT_CAR_VIEW_INTERIOR === CONFIGURATOR.animations.frontseats);

    if (fromAction) {
      GTM.track({
        dlCategory: 'Customize',
        dlAction: 'Interior',
        dlLabel: value ? 'On' : 'Off',
        dlModel: car.slug,
      });
    }

    sendToConfigurator({
      action: value ? CONFIGURATOR.seeInterior : CONFIGURATOR.seeExterior,
    });
  }

  const setBackseats = () => {
    setShowBackseats(true);
    setShowFrontseats(false);

    GTM.track({
      dlCategory: 'Customize',
      dlAction: 'Change seats',
      dlLabel: 'Back seats',
      dlModel: car.slug,
    });

    sendToConfigurator({
      action: CONFIGURATOR.seeBackseats,
    });
  }

  const setFrontseats = () => {
    setShowBackseats(false);
    setShowFrontseats(true);

    GTM.track({
      dlCategory: 'Customize',
      dlAction: 'Change seats',
      dlLabel: 'Front seats',
      dlModel: car.slug,
    });

    sendToConfigurator({
      action: CONFIGURATOR.seeFrontseats,
    });
  }

  const toggleLights = () => {
    const on = !lightsOn;
    setLightsOn(on);

    GTM.track({
      dlCategory: 'Customize',
      dlAction: 'Lights',
      dlLabel:on ? 'On' : 'Off',
      dlModel: car.slug,
    });

    sendToConfigurator({
      action: CONFIGURATOR.toggleLights,
    });
  }

  const toggleFrontDoors = () => {
    const open = !frontDoorsOpen;
    setFrontDoorsOpen(open);

    GTM.track({
      dlCategory: 'Customize',
      dlAction: 'Door',
      dlLabel:`${CONFIGURATOR.animations.frontdoors} ${open ? 'Open' : 'Close'}`,
      dlModel: car.slug,
    });

    toggleAnimation(CONFIGURATOR.animations.frontdoors);
  }

  const toggleBackDoors = () => {
    const open = !backDoorsOpen;
    setBackDoorsOpen(open);

    GTM.track({
      dlCategory: 'Customize',
      dlAction: 'Door',
      dlLabel:`${CONFIGURATOR.animations.backdoors} ${open ? 'Open' : 'Close'}`,
      dlModel: car.slug,
    });

    toggleAnimation(CONFIGURATOR.animations.backdoors);
  }

  const toggleTrunk = () => {
    const open = !trunkOpen;
    setTrunkOpen(open);

    GTM.track({
      dlCategory: 'Customize',
      dlAction: 'Door',
      dlLabel:`${CONFIGURATOR.animations.trunk} ${open ? 'Open' : 'Close'}`,
      dlModel: car.slug,
    });

    toggleAnimation(CONFIGURATOR.animations.trunk);
  }

  const renderColors = () => {
    return materials.map((material, i) => {
      const payload = JSON.parse(material.payload);
      let style = {};

      if (payload.swatches) {
        if (payload.swatches.length > 1) {
          style = {
            background: `linear-gradient(165deg, ${payload.swatches[0]} 0%, ${payload.swatches[0]} 49%, ${payload.swatches[1]} 50%)`,
          }
        } else {
          style = { backgroundColor: payload.swatches[0] };
        }
      } else if (payload.swatch) {
        style = { backgroundColor: payload.swatch };
      }

      return (
        <div className={`color${material.name === activeColor ? ' color--active' : ''}`} key={`car-material-${i}`} onClick={() => setColor(material.name)} title={ material.name }>
          <div className="color__swatch" style={style} />
        </div>
      );
    });
  }

  const hideControls = () => {
    sendToConfigurator({
      action: CONFIGURATOR.hideControls
    });
  };

  const onMessage = (e) => {
    if (!(e.origin.indexOf(currentLocale.domain) >= 0 || e.origin.indexOf(currentLocale.configurator) >= 0)) { return; }

    try {
      if (e.data.source === CONFIGURATOR.source && e.data.direction === CONFIGURATOR.message_from) {
        const { payload } = e.data;

        if (CONFIG.DEBUG) { console.log('received from configurator', payload); }

        if (payload.status === 'loading') {
          setLoading(true);
          setAnimations([]);
          setMaterials([]);
          setActiveColor([]);
        }

        if (payload.status === 'ready') {
          setLoading(false);

          setAnimations(payload.data.animations);
          setMaterials(payload.data.materials);
          setActiveColor(payload.data.defaultColor);
        }
      }
    } catch (e) {
      if (CONFIG.DEBUG) {
        console.warn(e);
      }
    }
  };

  const scrollToConfigurator = () => {
    if (!configurator || !configurator.current) { return; }
    configurator.current.scrollIntoView({
      behavior: 'smooth',
    });
  }

  // const scrollToContent = () => {
  //   if (!content || !content.current) { return; }
  //   content.current.scrollIntoView({
  //     behavior: 'smooth',
  //   });
  // }

  const scrollToConditions = (notrack) => {
    if (!conditions || !conditions.current) {return;}

    conditions.current.scrollIntoView({
      behavior: 'smooth',
    });

    if (notrack) { return; }

    GTM.track({
      dlCategory: 'Show conditions',
      dlAction: 'Click',
      dlModel: car.slug,
    });

  }

  const onBannerClose = () => {
    setShowBanner(false);
    setKeepClosed(true);
  };

  const onShowMore = () => {
    setInteractive(true);

    GTM.track({
      dlCategory: 'Show more',
      dlAction: 'Click',
      dlModel: car.slug,
    });

    if (!car.gforce_hash) {
      setLoading(false);
    }
  };

  const onSlideshowChange = (index) => {

    let dlLabel = '';

    if (index > activeSlide) {
      dlLabel = 'forward';
    } else {
      dlLabel = 'backward';
    }

    if (index === 0 & activeSlide === car.media.length - 1) {
      dlLabel = 'forward';
    }

    if (activeSlide === 0 && index === car.media.length - 1) {
      dlLabel = 'backward';
    }

    GTM.track({
      dlCategory: 'Carousel',
      dlAction: 'Navigate',
      dlLabel,
      dlModel: car.slug,
    });

    setActiveSlide(index);
  }

  const onVideoPlay = (e) => {
    const { target: player } = e;

    GTM.track({
      dlCategory: 'Video',
      dlAction: 'Play',
      dlLabel: player.getVideoData().title,
      dlModel: car.slug,
    });

  };

  const onVideoEnd = (e) => {
    const { target: player } = e;

    GTM.track({
      dlCategory: 'Video',
      dlAction: 'Complete',
      dlLabel: player.getVideoData().title,
      dlModel: car.slug,
    });
  };

  const renderAnimations = () => (
    <>
      { !showInterior ? (<Button icon={<Icon icon="lights" />} color={ lightsOn ? 'primary' : 'configuratordisabled' } onClick={toggleLights} title={intl.formatMessage({ id: 'configurator.lights' })} round="true"/>) : null }
      { showInterior ? (<Button icon={<Icon icon="frontseats" />} color={ showFrontseats ? 'primary' : 'configuratordisabled' } onClick={setFrontseats} title={intl.formatMessage({ id: 'configurator.frontseats' })} />) : null }
      { showInterior ? (<Button icon={<Icon icon="backseats" />} color={ showBackseats ? 'primary' : 'configuratordisabled' } onClick={setBackseats} title={intl.formatMessage({ id: 'configurator.backseats' })} />) : null }
      { animations.includes(CONFIGURATOR.animations.trunk) && !showInterior ? (<Button icon={<Icon icon="trunk" />} color={ trunkOpen ? 'primary' : 'configuratordisabled' } onClick={toggleTrunk} title={intl.formatMessage({ id: 'configurator.trunk' })} />) : null }
      { animations.includes(CONFIGURATOR.animations.frontdoors) ? (<Button icon={<Icon icon="frontdoors" />} color={ frontDoorsOpen ? 'primary' : 'configuratordisabled' } onClick={toggleFrontDoors} title={intl.formatMessage({ id: 'configurator.frontdoors' })} />) : null }
      { animations.includes(CONFIGURATOR.animations.backdoors) ? (<Button icon={<Icon icon="backdoors" />} color={ backDoorsOpen ? 'primary' : 'configuratordisabled' } onClick={toggleBackDoors} title={intl.formatMessage({ id: 'configurator.backdoors' })} />) : null }
    </>
  );

  const renderViews = () => (
    <div className="car__view__toggle">
      <Button className="car__view__toggle__button" onClick={() => setInterior(false, true)} size={ mobile ? 'xsmall' : 'small' } color={ !showInterior ? 'primary' : 'configuratorchoicedisabled'}><FormattedMessage id="configurator.exterior" /></Button>
      <Button className="car__view__toggle__button" onClick={() => setInterior(true, true)} size={ mobile ? 'xsmall' : 'small' } color={ showInterior ? 'primary' : 'configuratorchoicedisabled'}><FormattedMessage id="configurator.interior" /></Button>
    </div>
  );

  const scrollColorsLeft = () => {
    const { current: wrapper } = colorsWrapper;

    if (!wrapper) { return; }

    if (CONFIG.DEBUG) { console.log('scroll colors left'); }

    wrapper.scrollBy(-40, 0);
  };
  const scrollColorsRight = () => {
    const { current: wrapper } = colorsWrapper;

    if (!wrapper) { return; }

    if (CONFIG.DEBUG) { console.log('scroll colors right'); }

    wrapper.scrollBy(40, 0);
  };
  const onColorsScroll = (e) => {
    const { target } = e;

    setCanColorsLeft(target.scrollLeft > 0);
    setCanColorsRight(target.scrollLeft < target.scrollWidth - target.offsetWidth);
  };

  const openBanner = () => {
    setKeepClosed(false);
    setShowBanner(true);
  };

  const renderCarTitle = () => {
    if (!car) { return null; }

    // todo: move title field to backend
    if (car.price_default_short_name.toLowerCase().includes('sportage')) {
      return intl.formatMessage({ id: 'car.title.sportage' });
    }
    if (car.price_default_short_name.toLowerCase().includes('stonic')) {
      return intl.formatMessage({ id: 'car.title.stonic' });
    }
    if (car.price_default_short_name.toLowerCase().includes('ev6')) {
      return intl.formatMessage({ id: 'car.title.ev6' });
    }
    if (car.price_default_short_name.toLowerCase().includes('niro')) {
      return intl.formatMessage({ id: 'car.title.niro' });
    }

    if (newCars.includes(car.price_default_short_name)) {
      return intl.formatMessage({ id: 'car.title.new' }, { car: car.price_default_short_name });
    }

    return intl.formatMessage({ id: 'car.title' }, { car: car.price_default_short_name });
  };

  useEffect(() => {
    if (!powertrains.current) { return; }

    observer.observe(powertrains.current);

    return () => {
      if (!powertrains.current) { return; }
      observer.unobserve(powertrains.current)
    }
  }, [powertrains.current]);

  useEffect(() => {
    if (!configurator.current) { return; }

    observer.observe(configurator.current);

    return () => {
      if (!configurator.current) { return; }
      observer.unobserve(configurator.current);
    }
  }, [configurator.current]);

  useEffect(() => {

    if (colorsWrapper.current) {
      colorsWrapper.current.addEventListener('scroll', onColorsScroll);
    }

    return () => {
      if (colorsWrapper.current) {
        colorsWrapper.current.removeEventListener('scroll', onColorsScroll);
      }
    };

  }, [colorsWrapper.current]);

  useEffect(() => {
    if (!banner.current) { return; }

    observer.observe(banner.current);

    return () => {
      if (!banner.current) { return; }
      observer.unobserve(banner.current);
    }
  }, [banner.current]);

  useEffect(() => {
    setShowBanner(!interactive);
  }, [interactive]);

  useEffect(() => {
    if (loading) { return; }
    if (!mobile) { return; }

    document.querySelector('.car__configurator__actions').scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });
  }, [loading, mobile]);

  useEffect(() => {
    reset();
    init();
  }, [params, car])

  useEffect(() => {
    window.addEventListener('message', onMessage, false);
    window.addEventListener('focus', hideControls, false);

    return () => {
      window.removeEventListener('message', onMessage);
      window.removeEventListener('focus', hideControls);
    }
  }, []);

  let className = [];

  if (interactive) { className.push('page--car--open'); }
  if (showInterior) { className.push('page--car--interior'); }

  return car ? (
    <Page
      chat={(mobile && showTop) || !mobile}
      className={ className.join(' ') }
      fixedFooter={!interactive && !mobile}
      fixedHeader
      footer
      // lightFooter
      lightHeader
      dlLabel={car.slug}
      dlModel={car.slug}
      name="car"
    >
      { !interactive && !mobile ? <BackButton back={backToShowroom} /> : null }
      { interactive ? (
      <CarDetails showMore={interactive} car={car} showTop={showTop} back={interactiveBack} showMonthlyPrices={showMonthlyPrices}>
        { car.gforce_hash && !loading && !mobile ? (
          <>
          <Box className={`car__configurator__actions ${!showTop ? ' show-actions' : ''}`}>
            { showTop ? (
              <Button mode="naked" size="small" onClick={scrollToConfigurator}><FormattedMessage id="configurator.backtomodel" /></Button>
            ) : (
              <>
                <h4 className="p--2 bold mt--0 mb--1"><FormattedMessage id="configurator.discover" values={{ car: car.price_default_short_name }} /></h4>
                <div className="configurator__controls">
                  <div className="car__controls">
                    <div className="car__view my--2">{ renderViews() }</div>
                    <div className="car__animations mt--2">{ renderAnimations() }</div>
                  </div>

                  <div className="car__colors__wrapper mt--2">
                    <h4 className="p--2 bold my--0"><FormattedMessage id="configurator.color" /></h4>
                    <p className="p--2 my--0">{ activeColor }</p>
                    <div className="car__colors mt--2">
                      { renderColors() }
                    </div>
                    <div className="mt--3">
                      <small>
                        { isB2c ? <><FormattedMessage id={'detail.price.disclaimer.b2c.photo'} /> <FormattedMessage id={'detail.price.disclaimer.b2c.loan'} /></> : <FormattedMessage id={'detail.price.disclaimer.b2b'} />}
                      </small>
                    </div>
                  </div>
                </div>
              </>
            ) }
          </Box>
          </>
        ) : null }
      </CarDetails>
      ) : null }

      <div className={`car__configurator${ loading ? '' : ' car__configurator--loaded'}`} ref={configurator} style={{ backgroundImage: loading ? `url(${encodeURI(car.image)})` : 'none' }}>
        { car && car.gforce_hash && interactive ? (<iframe id="configurator" title="configurator" src={`https://${currentLocale.configurator}${prefix}${ROUTES.cars}/${car.slug}/configurator/${car.gforce_hash}?dpr=1&host=kia.com`} className="car__configurator__iframe" />) : null }
        { car ? (<CarPreview movePrice={movePrice} loading={car.gforce_hash && interactive} showControls={!interactive} className={car.gforce_hash && interactive ? 'hide' : ''} car={car} back={backToShowroom} onShowMore={onShowMore} scrollToConditions={scrollToConditions}/>) : null }

        { car.gforce_hash && !loading && mobile && interactive ? (
          <>
          {/* { showHelp ? (
            <div className="car__help">
              <Info visible={showHelp && !loading} close onClose={() => setShowHelp(false)}>
                <p className="my--0">Move around the car by dragging the screen. You can turn on the lights, open the doors, switch between interior and exterior and pick your favorite color from the color selection</p>
              </Info>
            </div>
          ) : null } */}
          <Box className="car__configurator__actions mt--1">
            { showTop ? (
              <Button mode="naked" className="button--model" size="small" onClick={scrollToConfigurator}><FormattedMessage id="configurator.backtomodel" /></Button>
            ) : (
              <>
                <div className="configurator__controls">
                  <div className="car__controls">
                    <h4 className="p--2 bold my--0"><FormattedMessage id="configurator.discover" values={{ car: car.price_default_short_name }} /></h4>
                    <div className="car__controls__content">
                      <div className="car__view">{ renderViews() }</div>
                      <div className="car__animations">{ renderAnimations() }</div>
                    </div>
                  </div>

                  <div className={`car__colors__wrapper`}>
                    <div className="car__colors__title">
                      <h4 className="p--2 bold my--0 mr--2"><FormattedMessage id="configurator.color" /></h4>
                      <p className="p--2 my--0">{ activeColor }</p>
                    </div>
                    <div className={`car__colors__content${ materials.length > 8 ? ' car__colors__content--scroll' : '' }`}>
                      { materials.length > 8 ? (<Button color="transparent" className="fc--text" disabled={!canColorsLeft} icon={<Icon icon="arrow-left" />} onClick={scrollColorsLeft} />) : null }
                      <div className={`car__colors`} ref={colorsWrapper}>
                        { renderColors() }
                      </div>
                      { materials.length > 8 ? (<Button color="transparent" className="fc--text" disabled={!canColorsRight} icon={<Icon icon="arrow-right" />} onClick={scrollColorsRight} />) : null }
                    </div>
                    <div className="mt--3"><small>{ isB2c ? <><FormattedMessage id={'detail.price.disclaimer.b2c.photo'} /> <FormattedMessage id={'detail.price.disclaimer.b2c.loan'} /></> : <FormattedMessage id={'detail.price.disclaimer.b2b'} />}</small></div>
                  </div>
                </div>
              </>
            ) }
          </Box>
          </>
        ) : null }
      </div>
      <div>
      <ButtonBanner showMore={interactive} car={car} movePrice={movePrice} ref={banner} />
      { !interactive ? (
      <div className="car__content__wrapper">
        <div className="car__content" ref={content}>
          {/* <div className={`car__scrolltriggers${ loading ? ' car__scrolltriggers--loading' : '' }`}>
            { showTop ? (
              <Button icon={<Icon icon="less" />} mode="naked" onClick={scrollToConfigurator} className="scrolltrigger"><FormattedMessage id="configurator.backtomodel" /></Button>
            ) : (
              <Button icon={<Icon icon="more" />} mode="naked" onClick={scrollToContent} className="scrolltrigger uppercase"><FormattedMessage id="configurator.readmore" /></Button>)
            }
          </div> */}
          { car.media.length > 0 ? (
            <div className="-mt--5 mb--20">
              <CarouselSlick
                items={car.media}
                onChange={onSlideshowChange}
                onVideoEnd={onVideoEnd}
                onVideoPlay={onVideoPlay}
              />
              <div className="p--2 mt--1 mb--0" dangerouslySetInnerHTML={{__html: car.media[activeSlide].body }} />
            </div>
          ) : null }
          <div className="car__content__description__wrapper">
            <div className="car__content__description">
              <h4 className="heading heading--3">{ renderCarTitle() }</h4>

              <div className="car__description mt--3" dangerouslySetInnerHTML={{__html: car.description}} ></div>

              <div className="car__links">
                { car.more_info_link ? (<Button size="small" href={car.more_info_link} className="button__label__underlined"><FormattedMessage id="car.info" /></Button>) : null }
                { !mobile && car.more_info_link && car.brochure_link ? <p className="mr--5">|</p> : null }
                { car.brochure_link && !mobile ? (<Button size="small" href={car.brochure_link} className="button__label__underlined ml--2"><FormattedMessage id="car.brochure" /></Button>) : null }
              </div>

              <div className="heading heading--3 car__conditions mt--8" ref={conditions}>{bms ? <FormattedMessage id={`info.conditionsbms.${isB2c ? "b2c" : "b2b"}`} /> : <FormattedMessage id={`info.conditions.${isB2c ? "b2c" : "b2b"}`} />}</div>

              {isB2c && <div className="car__description mt--3"><FormattedMessage id="easymove.legal" /></div>}
            </div>
          </div>
          <div className="car__powertrains mt--3" ref={powertrains} id="powertrains">
            { car.engine.map((powertrain, i) => (
              <Powertrain
                {...powertrain}
                action_valid_to={car.action_valid_to}
                model={car.slug}
                car={car}
                key={`powertrain--${i}`}
                highlight={powertrain.highlight}
                inspire={powertrain.inspire}
              />)) }
          </div>

          { car.stock_locator_link  ? (
            <div className="car__stocklocator">
              <Button size="small"   href={car.stock_locator_link} className="mt--2 mr--2"><FormattedMessage id="car.stock_locator" /></Button>
            </div>
          ) : null }
          {legal && <div className="car__legal__wrapper">
            <div className="car__legal mt--8">
              <div className="car__legal__column mr--4">
                { legal.discount ? (<div className="p--2" dangerouslySetInnerHTML={{__html: legal.discount}} />) : null }
                { legal.bonus ? (<div className="p--2" dangerouslySetInnerHTML={{__html: legal.bonus}} />) : null }
                { legal.promo_price ? (<div className="p--2" dangerouslySetInnerHTML={{__html: legal.promo_price}} />) : null }
              </div>
              <div className="car__legal__column">
                { legal.wltp ? (<div className="p--2" dangerouslySetInnerHTML={{__html: legal.wltp}} />) : null }
                { legal.ndec ? (<div className="p--2" dangerouslySetInnerHTML={{__html: legal.ndec}} />) : null }
                { legal.disclaimer ? (<div className="p--2" dangerouslySetInnerHTML={{__html: legal.disclaimer}} />) : null }
                { !isB2c && legal.kialeasedisclaimer ? (<div className="p--2" dangerouslySetInnerHTML={{__html: legal.kialeasedisclaimer}} />) : null }
                { isB2c && legal.kiaprivateleasedisclaimer ? (<div className="p--2" dangerouslySetInnerHTML={{__html: legal.kiaprivateleasedisclaimer}} />) : null }
                { legal.warranty ? (
                  <div className="car__legal--warranty">
                    { currentLocale.slug === 'nl' ? (<img src={img_warranty_nl} alt="7 year warranty" />) : null }
                    { currentLocale.slug === 'fr' ? (<img src={img_warranty_fr} alt="7 year warranty" />) : null }
                    <div className="p--2" dangerouslySetInnerHTML={{__html: legal.warranty}} />
                  </div>
                ) : null }
              </div>
            </div>
            <div className="car__legal__devider mt--4" />
            { showMonthlyPrices && isB2c ? (<h4 className="heading fs--h3 py--4 fc--secondary"><FormattedMessage id="legal.attention" /></h4>) : null }
          </div> }
        </div>
      </div>
      ) : null }
      { showMonthlyPrices && isB2c ? (
        <React.Fragment>
          { (showBanner && !keepClosed) ? (
            <Banner name="easymove" actions={<Button mode="outline" color="light" size="small" onClick={onBannerClose}>
              <FormattedMessage id="banner.ok" /></Button>}>
              <Button size="small" className="banner__trigger" onClick={onBannerClose} icon={<Icon icon="arrow-circle-up" style={{width: '16px', height: '16px', transform: 'rotate(180deg)'}} />} iconAfter>Legal Info</Button>
              <h4 className="fs--legal"><FormattedMessage id="legal.attention" /></h4>
              <p><FormattedMessage id="banner.example" />:</p>
              { car.representative_example ? (<div dangerouslySetInnerHTML={{__html: car.representative_example }} className="p--2" />) : (<div dangerouslySetInnerHTML={{__html: legal.representative_example }} className="p--2" />) }
            </Banner>
          ) : (
            <Button size="small" className="banner__trigger" onClick={openBanner} icon={<Icon icon="arrow-circle-up" style={{width: '16px', height: '16px'}} />} iconAfter>Legal Info</Button>
          ) }
        </React.Fragment>) : null }
      </div>
    </Page>
  ) : (<Loading page />);
}
